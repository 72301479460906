.email-modal-container {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
}

.email-modal {
    border-radius: 5px;
    padding: 2rem;
    background-color: white;
    width: 25em;
}

.email-modal .btn {
    display: block;
    margin: auto;
}

.email-modal .form-group {
    margin-bottom: 1rem;
}

.email-modal .error {
    background-color: #f8d7da;
    color: #df4759;
    padding: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
}