.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #faf9f6;
}

.search-container {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.search-input {
  border: 1px solid #00485a;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 1rem;
}

.btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: none;
  background-color: #1fd2ff;
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 5px 5px #ccc;
  transition: 0.3s;
}

.btn:hover {
  background-color: #00485a;
  color: #fff;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.form-group input,
.form-group select {
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.3rem;
  font-size: 1rem;
}

.form-group label {
  margin-bottom: 0.2rem;
}

.login-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 5px 15px #ccc;
  width: 300px;
  /* Adjust the width as needed */
  margin: auto;
  /* Center the card horizontally */
  margin-top: 50px;
  /* Add some top margin */
}

.login-card h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #00485a;
}

.login-input {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.8rem;
  border: 1px solid #00485a;
  border-radius: 5px;
  font-size: 1rem;
}

.login-label {
  color: #00485a;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.login-error {
  color: #ff3333;
  margin-top: 0.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.login-button {
  background-color: #1fd2ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  box-shadow: 0px 5px 5px #ccc;
  transition: 0.3s;
}

.login-button:hover {
  background-color: #00485a;
}